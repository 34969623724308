import "./index.css";
import "./web.config";

import * as Constants from "./constants";
import * as helper from "./menuhelper";
import * as menuApi from "./api/menuApi";

import { Route, Router } from "react-router-dom";
import { StylesProvider, createGenerateClassName } from "@material-ui/core";
import { createHashHistory } from 'history';
import App from "./components/app/App";
import CustomError from "./components/shared/CustomError";
import GenericDialog from "./components/shared/GenericDialog";
import React from "react";
import ReactDOM from "react-dom";
import TelemetryProvider from './TelemetryProvider';
import TelemetryLogger from "@mintstudiouicore/telemetry";
import { registerMicroFrontEnd } from "./lib/spaLoader";
import themes from "./palette";

async function init() {
  // Check that the window is an iframe and not popup
  if (window !== window.parent || window.opener || window.top !== window) {
    return;
  }
  
  // using JSON.parse to convert the HIDE_OCV value
  // to a boolean. We are using JSON.parse to avoid the compiler
  // removing the if check.
  if (!JSON.parse(process.env.HIDE_OCV)) {
    loadOneCustomerVoiceFiles();
  }
  await window.AuthProvider.signIn();
  window.httpService.fiscalYear = localStorage.getItem(Constants.LAST_FISCAL_YEAR_SELECTED) || 2024;

  if (window.AuthProvider.errorObj == null) {
    await fetchFiscalYears();
  } else {
    renderError();
  }
}

function loadOneCustomerVoiceFiles () {
    const ocvJs = document.createElement('script');
    ocvJs.setAttribute('src', `${process.env.ASSET_URL}/OCV/officebrowserfeedback.js`);
    const ocvCss = document.createElement('link');
    ocvCss.setAttribute('rel', 'stylesheet');
    ocvCss.setAttribute('href', `${process.env.ASSET_URL}/OCV/officebrowserfeedback_vertical.css`);
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(ocvJs);
    head.appendChild(ocvCss);
}

function fetchFiscalYears() {
  menuApi
    .getFiscalYears()
    .then((fiscalYears) => {
      if (fiscalYears == null || fiscalYears.length === 0) {
        throw new Error("Could not find fiscal years in response.");
      }

      let defaultFiscalYear = fiscalYears.filter((p) => p["default"] === true)[0];

      defaultFiscalYear = defaultFiscalYear || fiscalYears[0];
      let previousSelectedFiscalYear = localStorage.getItem(Constants.LAST_FISCAL_YEAR_SELECTED);

      if (
        previousSelectedFiscalYear == null ||
        fiscalYears.filter((p) => p["year"].toString() === previousSelectedFiscalYear.toString())
          .length === 0
      ) {
        localStorage.setItem(Constants.LAST_FISCAL_YEAR_SELECTED, defaultFiscalYear["year"]);
      }

      previousSelectedFiscalYear = localStorage.getItem(Constants.LAST_FISCAL_YEAR_SELECTED);
      let activeFiscalYear = fiscalYears.filter((p) => p["year"].toString() === previousSelectedFiscalYear.toString())[0];

      window.httpService.fiscalYear = activeFiscalYear["year"];
      window.httpService.planningCycle = activeFiscalYear["planningCycle"];
      window.httpService.currentSeason = activeFiscalYear["currentSeason"];

      if (window.AuthProvider.errorObj == null) {
        initializeShell(fiscalYears);
      } else {
        renderError();
      }
    })
    .catch((error) => {
      const errorMsg = error.data ? error.data.ExceptionMessage : "";
      ReactDOM.render(
        <GenericDialog
          dismissable={false}
          description={errorMsg}
          title="Could not fetch fiscal years."
          isOpen={true}
        />,
        document.getElementById("root")
      );
    });
}

async function initializeShell(fiscalYears) {
  menuApi.checkMSSalesAccess().then((res) => {
    if (res && res.length) {
      menuApi.getMenuItems()
        .then((items) => {
          const resources = helper.getResourcesFromMenuItems(items);
          if(!resources.find((r) => r.resourceName === "businessreview")) {
            menuApi.checkIfSellerManager().then((res) => {
              if (res) {
                console.log("Business Review is enabled for this user");
                resources.push({ resourceName: "businessreview", isStoreEnabled: false });
                items.push({
                  title: "Business Review",
                  pathName: "businessreview",
                  isStoreEnabled: false,
                  children: []
              })
              }
             
            }).catch((error) => {
                if(error.data.statusCode != 404 || error.data.message != "Resource not found") {
                  ReactDOM.render(
                    <GenericDialog
                      dismissable={false}
                      description={error.data.message}
                      title= "Is Seller Manager Check failed."
                      isOpen={true}
                    />,
                    document.getElementById("root")
                  );
                }
            }).finally(() => {
              TelemetryLogger.initializeTelemetry();
              registerMicroFrontEnd(resources, TelemetryLogger);
              renderShell(items, resources, fiscalYears, undefined);
            });
          } else{
            TelemetryLogger.initializeTelemetry();
            registerMicroFrontEnd(resources, TelemetryLogger);
            renderShell(items, resources, fiscalYears, undefined);
          }
        }).catch((error) => {
          const errorMsg = error.data ? error.data.ExceptionMessage : "";
          ReactDOM.render(
            <GenericDialog
              dismissable={false}
              description={errorMsg}
              title={errorMsg.length > 15 ? `${errorMsg.substring(0, 15)}...` : `${errorMsg}`}
              isOpen={true}
            />,
            document.getElementById("root")
          );
        });
    } else {
      ReactDOM.render(
        <GenericDialog
          dismissable={false}
          description={"MS Sales access not found."}
          title="Could not fetch MS Sales Access."
          isOpen={true}
        />,
        document.getElementById("root")
      );
    }
  }).catch(() => {
    ReactDOM.render(
      <GenericDialog
        dismissable={false}
        description={"MS Sales access not found."}
        title="Could not fetch MS Sales Access."
        isOpen={true}
      />,
      document.getElementById("root")
    );
  })
}

init();

const generateClassName = createGenerateClassName({
  seed: "shell"
});

function renderError() {
  ReactDOM.render(
    <CustomError errorObj={window.AuthProvider.errorObj} />,
    document.getElementById("root")
  );
}

function getLastActiveTheme() {
  let themeName = localStorage.getItem("themeName");
  if (themeName === "Dark Theme") {
    return themes.dark;
  } else {
    return themes.light;
  }
}

function renderShell(menuItems, resources, fiscalYears, error) {
  ReactDOM.render(   
      <StylesProvider generateClassName={generateClassName}>
        <Router history = {createHashHistory()}>
          <TelemetryProvider>
            <Route
              path="/"
              render={(props) => (
                <App
                  {...props}
                  menuItems={menuItems}
                  resources={resources}
                  fiscalYears={fiscalYears}
                  error={error}
                  lastActiveTheme={getLastActiveTheme()}
                />
              )}
            />
          </TelemetryProvider>
        </Router>
      </StylesProvider>,    
    document.getElementById("root")
  );
}